import { Injectable } from '@angular/core';
import { LoadingController, AlertController, Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class Settings {
    constructor() {

    }

    companyTheme = {
        primaryColor: '#611983',
        secondaryColor: '#333645',
        primaryTextColor: '#2D4260',
        topLogo: 'https://portal.shergroup.net/assets/images/logos/shergroup-logo.png'
    };

    companyInformation = {
        companyName: 'Shergroup',
        companyName2: '',
        companyName3: '',
        address1: '',
        address2: '',
        address3: '',
        postalCode: '',
        VATNr: '',
        email: 'brendan@dsisa.co.za',
        email2: '',
        tel1: '',
        tel2: '',
        tel3: '',
        companyTheme: {
            primaryColor: '#611983',
            secondaryColor: '#EC5656',
            primaryTextColor: '#2D4260',
            topLogo: 'https://portal.shergroup.net/assets/images/logos/shergroup-logo.png',
            success: 'https://mymobileapp.co.za/webservice/MPL-153/logos/success.png',
            fail: 'https://mymobileapp.co.za/webservice/MPL-153/logos/fail.png'
        }
    };

    billInformation = {
        amount: '1760.99',
        payReference: '#RG67J024', // ACCOUNT NUMBER
        billReference: 'Q000123' // JAN2019
    };

    getCompanyTheme() {
        return this.companyTheme;
    }

    getCompanyInformation() {
        return this.companyInformation;
    }

    getBillInformation() {
        return this.billInformation;
    }
}
